import React from 'react';
import ReactDOM from 'react-dom';
import {ChatWidget, KeyCRM} from './index';

function createElement() {
  const el = document.createElement('div');
  el.id = 'keycrm-' + (Math.random() + 1).toString(20).substring(2);
  document.body.appendChild(el);
  return el.id;
}

export default {
  ...KeyCRM,
  config: {},
  render: (config: object = {}) => {
    ReactDOM.render(
      React.createElement(ChatWidget, config),
      document.getElementById(createElement())
    );
  },
};
