import React, {CSSProperties} from 'react';
import {Button, Text} from 'theme-ui';
import {motion} from 'framer-motion';

const Path = (props: any) => (
  <motion.path
    fill='transparent'
    strokeWidth='3'
    stroke='hsl(0, 0%, 99%)'
    strokeLinecap='round'
    {...props}
  />
);
//
// const DefaultToggleIcon = () => {
//   return (
//     <svg width='27' height='27' viewBox='0 0 27 27'>
//       <Path
//         variants={{
//           closed: {opacity: 0, d: 'M 7.5 14.5 L 19 14.5'},
//           open: {opacity: 1, d: 'M 7 7 L 20 20'},
//         }}
//         transition={{duration: 0.2}}
//       />
//
//       <Path
//         variants={{
//           closed: {opacity: 0, d: 'M 7.5 8.5 L 19 8.5'},
//           open: {opacity: 1, d: 'M 7 20 L 20 7'},
//         }}
//         transition={{duration: 0.2}}
//       />
//
//       <Path
//         d='M22 21.6453C22 20 23 19.5 23 19.5C23 19.5 25.5 18 25.5 14V9C25.5 4 23 1.5 18 1.5H9C4 1.5 1.5 4 1.5 9V14C1.5 19 4 21 9 21H13.5C14 21 14 21 15 21.5L20.25 24.8572L20.8517 25.2118C21.5184 25.6046 22 25.631 22 24.8572V24.0287V22.7858V21.6453Z'
//         variants={{
//           closed: {opacity: 1},
//           open: {opacity: 0},
//         }}
//         transition={{duration: 0.2}}
//       />
//     </svg>
//   );
// };

export const ToggleIconFilled = () => {
  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none'>
      <Path
        variants={{
          closed: {opacity: 0, d: 'M 7.5 14.5 L 19 14.5'},
          open: {opacity: 1, d: 'M 5 5 L 20 20'},
        }}
        transition={{duration: 0.2}}
      />

      <Path
        variants={{
          closed: {opacity: 0, d: 'M 7.5 8.5 L 19 8.5'},
          open: {opacity: 1, d: 'M 5 20 L 20 5'},
        }}
        transition={{duration: 0.2}}
      />

      <motion.path
        d='M16.875 6.75H7.125C6.50625 6.75 6 7.25625 6 7.875C6 8.49375 6.50625 9 7.125 9H16.875C17.4984 9 18 8.49844 18 7.875C18 7.25156 17.4984 6.75 16.875 6.75ZM12.375 11.25H7.125C6.50625 11.25 6 11.7562 6 12.375C6 12.9938 6.50625 13.5 7.125 13.5H12.375C12.9984 13.5 13.5 12.9984 13.5 12.375C13.5 11.7516 12.9984 11.25 12.375 11.25Z'
        fill='white'
        fillOpacity='0.7'
        variants={{
          closed: {opacity: 1},
          open: {opacity: 0},
        }}
        transition={{duration: 0.2}}
      />
      <motion.path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.9578 0H2.95779C1.30545 0 -0.0422058 1.34766 -0.0422058 2.95781V16.4156C-0.0422058 18.068 1.30545 19.3734 2.95779 19.3734H7.45779V23.2687C7.45779 23.7258 7.98514 23.993 8.35404 23.7234L14.2087 19.3312H20.9587C22.6111 19.3312 23.9587 17.9836 23.9587 16.3734V2.95781C23.9578 1.34766 22.65 0 20.9578 0ZM12.375 13.5H7.12498C6.50623 13.5 5.99998 12.9984 5.99998 12.375C5.99998 11.7516 6.50623 11.25 7.12498 11.25H12.375C12.9984 11.25 13.5 11.7562 13.5 12.375C13.5 12.9937 12.9984 13.5 12.375 13.5ZM16.875 9H7.12498C6.50623 9 5.99998 8.49844 5.99998 7.875C5.99998 7.25156 6.50623 6.75 7.12498 6.75H16.875C17.4984 6.75 18 7.25625 18 7.875C18 8.49375 17.4984 9 16.875 9Z'
        fill='white'
        variants={{
          closed: {opacity: 1},
          open: {opacity: 0},
        }}
        transition={{duration: 0.2}}
      />
    </svg>
  );
};
// V2
// export const ToggleIconFilled = () => {
//   return (
//     <svg
//       width='32'
//       height='32'
//       viewBox='0 0 32 32'
//       fill='none'
//       xmlns='http://www.w3.org/2000/svg'
//     >
//       <Path
//         variants={{
//           closed: {opacity: 0, d: 'M 7.5 14.5 L 19 14.5'},
//           open: {opacity: 1, d: 'M 5 5 L 20 20'},
//         }}
//         transition={{duration: 0.2}}
//       />
//
//       <Path
//         variants={{
//           closed: {opacity: 0, d: 'M 7.5 8.5 L 19 8.5'},
//           open: {opacity: 1, d: 'M 5 20 L 20 5'},
//         }}
//         transition={{duration: 0.2}}
//       />
//       <motion.path
//         d='M32 12.755V22.31C32 24.0725 30.5625 25.465 28.8 25.465H27.2V28.177C27.2 28.5769 26.7437 28.808 26.425 28.5706L22.2875 25.4646L15.955 25.555C14.235 25.555 12.8 24.16 12.8 22.355V20.756L17.6 20.7559C20.244 20.7559 22.4 18.5999 22.4 15.9564V9.59998H28.8C30.565 9.59998 32 11.035 32 12.755Z'
//         fill='white'
//         fillOpacity='0.7'
//         variants={{
//           closed: {opacity: 1},
//           open: {opacity: 0},
//         }}
//         transition={{duration: 0.2}}
//       />
//       <motion.path
//         fillRule='evenodd'
//         clipRule='evenodd'
//         d='M17.6 3.19995H3.2C1.4375 3.19995 0 4.63745 0 6.35495V16C0 17.76 1.4375 19.2 3.2 19.2L4.8 19.2004V21.9129C4.8 22.3128 5.25625 22.5439 5.575 22.3067L9.7125 19.2007L17.6 19.195C19.3625 19.2012 20.8 17.761 20.8 15.999V6.35495C20.8 4.63745 19.365 3.19995 17.6 3.19995Z'
//         fill='white'
//         variants={{
//           closed: {opacity: 1},
//           open: {opacity: 0},
//         }}
//         transition={{duration: 0.2}}
//       />
//     </svg>
//   );
// };
//
// const DefaultCloseIcon = () => {
//   return (
//     <svg width='27' height='27' viewBox='0 0 27 27'>
//       <Path
//         variants={{
//           closed: {opacity: 0, d: 'M 7.5 14.5 L 19 14.5'},
//           open: {opacity: 1, d: 'M 7 7 L 20 20'},
//         }}
//         transition={{duration: 0.2}}
//       />
//
//       <Path
//         variants={{
//           closed: {opacity: 0, d: 'M 7.5 8.5 L 19 8.5'},
//           open: {opacity: 1, d: 'M 7 20 L 20 7'},
//         }}
//         transition={{duration: 0.2}}
//       />
//     </svg>
//   );
// };
// @ts-ignore
const ToggleIcon = (props: {
  isOpen?: boolean;
  customIconUrl?: string;
  iconVariant?: 'outlined' | 'filled';
}) => {
  return <ToggleIconFilled />;
  // TODO using custom icons for toggle and close widget
  // if (!customIconUrl) {
  //   return iconVariant === 'filled' ? (
  //     <ToggleIconFilled />
  //   ) : (
  //     <DefaultToggleIcon />
  //   );
  // }
  //
  // if (isOpen) {
  //   return <DefaultCloseIcon />;
  // } else {
  //   return <Image src={customIconUrl} style={{maxHeight: 40, maxWidth: 40}} />;
  // }
};

export const WidgetToggle = ({
  isOpen,
  isDisabled,
  customIconUrl,
  iconVariant,
  style,
  toggle,
  unreadMessages,
}: {
  isOpen?: boolean;
  isDisabled?: boolean;
  customIconUrl?: string;
  iconVariant?: 'outlined' | 'filled';
  style: CSSProperties;
  toggle: () => void;
  unreadMessages: number;
}) => {
  return (
    <Button
      className='KeyCRM-toggleButton'
      variant='primary'
      p={0}
      style={style}
      sx={{
        variant: 'styles.WidgetToggle',
      }}
      disabled={isDisabled}
      onClick={toggle}
      aria-label={`${isOpen ? 'Close' : 'Open'} chat widget`}
    >
      {unreadMessages > 0 && (
        <Text
          sx={{
            position: 'absolute',
            left: '35px',
            top: '-10px',
            background: '#FF5533',
            borderRadius: '50%',
            height: '20px',
            width: '20px',
            fontSize: '10px',
            paddingTop: '2px',
          }}
        >
          {unreadMessages}
        </Text>
      )}
      <ToggleIcon
        customIconUrl={customIconUrl}
        iconVariant={iconVariant}
        isOpen={isOpen}
      />
    </Button>
  );
};

export default WidgetToggle;
