import ChatWidget from './components/ChatWidget';
import ChatWindow from './components/ChatWindow';

export const open = () => window.dispatchEvent(new Event('keycrm:open'));
export const close = () => window.dispatchEvent(new Event('keycrm:close'));
export const toggle = () => window.dispatchEvent(new Event('keycrm:toggle'));

export const identify = () => {
  // TODO: add ability to create/update customer information
  // console.warn('`KeyCRM.identify` has not been implemented yet!');
};

export const KeyCRM = {
  open,
  close,
  toggle,
};

export {ChatWidget, ChatWindow};

export default ChatWidget;
