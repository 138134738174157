/** @jsx jsx */

import React, {CSSProperties} from 'react';
import {motion} from 'framer-motion';
import {jsx} from 'theme-ui';
import isMobile from 'is-mobile';
import WidgetToggle from './WidgetToggle';
import ChatWidgetContainer, {SharedProps} from './ChatWidgetContainer';
import ErrorBoundary from './ErrorBoundary';
import {Message} from '@keycrm/widget-bridge';

type ToggleButtonOptions = {
  isOpen: boolean;
  isDisabled: boolean;
  onToggleOpen: () => void;
};

type StyleOverrides = {
  frameOffset?: string;
  chatContainer?: CSSProperties;
  toggleContainer?: CSSProperties;
  toggleButton?: CSSProperties;
};

type PositionConfig = {
  side: 'left' | 'right';
  offset: number;
};

const DEFAULT_X_OFFSET = 20;

const normalizePositionConfig = (
  position?: 'left' | 'right' | PositionConfig
): PositionConfig => {
  if (!position) {
    return {side: 'right', offset: DEFAULT_X_OFFSET};
  }

  switch (position) {
    case 'left':
      return {side: 'left', offset: DEFAULT_X_OFFSET};
    case 'right':
      return {side: 'right', offset: DEFAULT_X_OFFSET};
    default:
      return position;
  }
};

const getDefaultStyles = (
  styles: StyleOverrides = {},
  position: PositionConfig
): StyleOverrides => {
  const {
    chatContainer: chatContainerStyle = {},
    toggleContainer: toggleContainerStyle = {},
    toggleButton: toggleButtonStyle = {},
    frameOffset,
  } = styles;
  const {side = 'right', offset = DEFAULT_X_OFFSET} = position;

  switch (side) {
    case 'left':
      return {
        chatContainer: {
          left: isMobile() ? 0 : offset,
          right: 'auto',
          ...chatContainerStyle,
        },
        toggleContainer: {left: offset, right: 'auto', ...toggleContainerStyle},
        toggleButton: toggleButtonStyle,
        frameOffset,
      };
    case 'right':
    default:
      return {
        chatContainer: {
          right: isMobile() ? 0 : offset,
          left: 'auto',
          ...chatContainerStyle,
        },
        toggleContainer: {right: offset, left: 'auto', ...toggleContainerStyle},
        toggleButton: toggleButtonStyle,
        frameOffset,
      };
  }
};

const getBottomOffset = (
  isOpen: boolean,
  isMobile: boolean,
  hideToggleButton: boolean,
  frameOffset?: any
) => {
  if (frameOffset) {
    return frameOffset;
  }
  if (isMobile) {
    return isOpen ? 0 : hideToggleButton ? 0 : 80;
  }

  return hideToggleButton ? 30 : 100;
};

type Props = SharedProps & {
  defaultIsOpen?: boolean;
  isOpenByDefault?: boolean;
  persistOpenState?: boolean;
  hideToggleButton?: boolean;
  iconVariant?: 'outlined' | 'filled';
  position?: 'left' | 'right' | PositionConfig;
  renderToggleButton?: (options: ToggleButtonOptions) => React.ReactElement;
  styles?: StyleOverrides;
};

const ChatWidget = (props: Props) => {
  const [height, setHeight] = React.useState<number | undefined>(undefined);
  const calculateHeight = (message?: Message) => {
    if (!message || !message.body || message.body.length < 100) {
      return setHeight(undefined);
    }
    setHeight(180);
  };
  return (
    <ErrorBoundary>
      <ChatWidgetContainer
        {...props}
        canToggle
        onChatOpened={calculateHeight}
        onChatClosed={calculateHeight}
        onMessageUnseen={(message) => calculateHeight(message)}
      >
        {(config) => {
          const {
            sandbox,
            isLoaded,
            isActive,
            isOpen,
            isTransitioning,
            customIconUrl,
            iframeUrl,
            query,
            shouldDisplayNotifications,
            setIframeRef,
            onToggleOpen,
            unreadMessagesCount,
          } = config;

          const {
            hideToggleButton,
            iconVariant,
            renderToggleButton,
            position = 'right',
            styles = {},
          } = props;
          const usesMobile = isMobile();
          const positionConfig = normalizePositionConfig(position);
          const {
            chatContainer: chatContainerStyle = {},
            toggleContainer: toggleContainerStyle = {},
            toggleButton: toggleButtonStyle = {},
            frameOffset,
          } = getDefaultStyles(styles, positionConfig);

          const bottomOffset = getBottomOffset(
            isOpen,
            usesMobile,
            hideToggleButton,
            frameOffset
          );

          return (
            <React.Fragment>
              <motion.iframe
                ref={setIframeRef}
                className='KeyCRM-chatWindowContainer'
                sandbox={sandbox}
                animate={isActive ? 'open' : 'closed'}
                initial='closed'
                variants={{
                  closed: {opacity: 0, y: 4},
                  open: {opacity: 1, y: 0},
                }}
                transition={{duration: 0.2, ease: 'easeIn'}}
                src={`${iframeUrl}?${query}`}
                style={{
                  height: !isOpen && height ? height : undefined,
                  maxHeight: !isOpen && height ? height : undefined,
                  bottom: bottomOffset,
                  visibility:
                    isOpen || shouldDisplayNotifications ? 'visible' : 'hidden',
                  ...(isActive
                    ? chatContainerStyle
                    : {
                        pointerEvents: 'none',
                        height: 0,
                        minHeight: 0,
                      }),
                }}
                sx={{
                  border: 'none',
                  bg: 'background',
                  variant:
                    !isOpen && shouldDisplayNotifications
                      ? 'styles.WidgetContainer.notifications'
                      : !usesMobile
                      ? 'styles.WidgetContainer'
                      : 'styles.WidgetContainer.mobile',
                }}
              >
                Loading...
              </motion.iframe>

              {isLoaded && !hideToggleButton && (
                <motion.div
                  className='KeyCRM-toggleButtonContainer'
                  initial={false}
                  style={toggleContainerStyle}
                  animate={isOpen ? 'open' : 'closed'}
                  sx={{
                    variant: 'styles.WidgetToggleContainer',
                  }}
                >
                  {renderToggleButton &&
                  typeof renderToggleButton === 'function' ? (
                    renderToggleButton({
                      isOpen,
                      onToggleOpen,
                      isDisabled: isTransitioning,
                    })
                  ) : (
                    <WidgetToggle
                      style={toggleButtonStyle}
                      isDisabled={isTransitioning}
                      isOpen={isOpen}
                      customIconUrl={customIconUrl}
                      iconVariant={iconVariant}
                      toggle={onToggleOpen}
                      unreadMessages={Number(unreadMessagesCount)}
                    />
                  )}
                </motion.div>
              )}
            </React.Fragment>
          );
        }}
      </ChatWidgetContainer>
    </ErrorBoundary>
  );
};

export default ChatWidget;
